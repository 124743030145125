@import url(./vars.css);
@import url(./onboard.css);
@font-face {
  font-family: 'Silkscreen';
  src: url('../asset/Silkscreen-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  }
  @font-face {
    font-family: 'IBM Plex Mono';
    src: url('../asset/IBMPlexMono-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    }
html,
body {
  padding: 0;
  margin: 0;
  font-family: Silkscreen;
  background-color: var(--color-background-paper);
}

main {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font: inherit;
}

* {
  box-sizing: border-box;
}

:root {
  --header-height: 52px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.illustration-main-fill {
  fill: var(--color-primary-main);
}

.illustration-light-fill {
  fill: var(--color-border-main);
}

.illustration-background-fill {
  fill: var(--color-logo-background);
}

/* Note: a fallback `stroke` property must be on the svg to work */
.illustration-main-stroke {
  stroke: var(--color-primary-main);
}

.illustration-light-stroke {
  stroke: var(--color-border-main);
}

.illustration-background-stroke {
  stroke: var(--color-logo-background);
}

@media (max-width: 600px) {
  .sticky {
    position: sticky;
    right: 0;
    background: var(--color-background-paper);
  }
}

body.beamerAnnouncementBarTopActive {
  padding-top: 0px !important;
}

#beamerLastPostTitle {
  left: 330px !important;
}